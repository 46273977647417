
import {
  Model,
  Schema,
  Options,
} from '@/types/vjsf';
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import VJsfForm from '@/components/commonComponents/VJsfForm.vue';
import Message from '@/components/mixins/Message.vue';
import Row from '@/helpers/mappers/DataTable/DataTableRow';
import { DocumentNode } from 'graphql';
import { Metadata } from '@/types/ListTypes';

@Component({
  name: 'DataTableEditableField',
  components: {
    VJsfForm,
    Message,
  },
  data: () => ({
    model: {},
    schema: { type: 'object', properties: {} },
    options: {} as Options,
    valid: null,
  }),
})
export default class DataTableEditableField extends Vue {
  @Prop({ required: true })
  private readonly VJsfTemplate!: { schema: Schema, options: Options, model: Model, metadata?: Metadata };

  @Prop({ required: true })
  private readonly modelMapper!: { [key: string]: string };

  @Prop({ required: true })
  private readonly rowValues!: Row;

  @Prop({ required: true })
  private readonly columnName!: string;

  @Prop()
  private readonly blockId?: number;

  @Prop()
  public readonly navigationSlug?: string;

  @Prop()
  public readonly dossierTypeSlug?: string;

  private loading = false;

  private showCheck = false;

  private showError = false;

  protected mounted(): void {
    this.convertVJsfToForm().then((form) => {
      this.$data.schema = form.schema;
      this.$data.options = form.options;
      this.$data.model = form.model;
      this.$data.metadata = form.metadata;
    });
  }

  private convertVJsfToForm(): Promise<{ schema: Schema, options: Options, model: Model, metadata?: Metadata }> {
    return new Promise((resolve) => {
      const {
        schema,
        options,
        model,
        metadata,
      } = this.VJsfTemplate;

      if (typeof schema === 'undefined') {
        throw Error('No form template found');
      }

      // Remove placeholder for autocomplete (select)
      options.messages = { search: '' };

      const overwriteProperties = {
        'x-class': 'pa-0 ma-0',
        'x-props': {
          dense: true,
          'hide-details': true,
          label: '',
          filled: false,
          solo: true,
          flat: true,
          outlined: true,
        },
        disabled: true,
      };

      // Set all the values on the model
      Object.keys(model).forEach((propertyName: string) => {
        const columnName = this.getColumnName(propertyName);
        if (columnName === '') {
          return;
        }
        model[propertyName] = this.rowValues.getCell(columnName).getValue();
        schema.properties[propertyName] = { ...schema.properties[propertyName], ...overwriteProperties };
      });

      resolve(
        {
          schema,
          options,
          model: { ...model },
          metadata,
        },
      );
    });
  }

  private getColumnName(propertyName: string): string {
    return Object.keys(this.modelMapper).find((key) => this.modelMapper[key] === propertyName) ?? '';
  }

  private submitData(): Promise<void> {
    this.loading = true;
    const variables = {
      navigationSlug: this.navigationSlug ?? this.$route.params.navigationSlug,
      dossierTypeSlug: this.dossierTypeSlug ?? this.$route.params.dossierTypeSlug,
      blockId: this.blockId,
      columnName: this.columnName,
      data: this.$data.model,
    };

    return this.import()
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then(() => {
        this.showError = false;
        this.showCheck = true;
        this.$emit('change');
        setTimeout(() => { this.showCheck = false; }, 5000);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        this.showError = true;
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private import(): Promise<{default: DocumentNode}> {
    let operation = 'dossier-type-list-inline-edit-form';
    if (this.$data.metadata?.multiColumn) {
      operation = 'dossier-type-list-multi-column-inline-edit-form';
    }

    if (typeof this.blockId !== 'undefined') {
      return import(`@/graphql/mutations/${operation}-by-block-id`);
    }
    return import(`@/graphql/mutations/${operation}`);
  }
}
